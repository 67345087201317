import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/home";
import Contact from "../pages/Contact/contact";
import About from "../pages/About/about";
import BookAppointment from "../pages/BookAppointment/bookAppointment";
import Login from "../pages/Login/login";
import SignUp from "../pages/Signup/signUp";
import SendForgotEmail from "../pages/forgotPassword/SendForgotEmail";
import ResetPassword from "../pages/forgotPassword/ResetPassword";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import AppointmentList from "../pages/appointmentList/appointmentList";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/book-appointment" element={<BookAppointment />} />

        <Route path="/forgot-password" element={<SendForgotEmail  />} />
        <Route path="/forgot-email" element={<ResetPassword  />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/appointment-list" element={<AppointmentList />} />


      </Routes>
    </>
  );
};

export default Routers;
