
//email Pattern
export const emailPattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

export const phonePattern = new RegExp(/^([+]\d{2})?\d{10}$/i);
 export const BASEURL = "https://studio-backend-m33e.onrender.com/v1"

export const about_slider = {
  dots: false,
  cssEase: "linear",
  className: "slider variable-width center",
  variableWidth: true,
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  margin : 20,


  responsive: [
   
     {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        margin:10,
      },
    },
  ],
};

export const about_slider_second = {
  dots: false,
  cssEase: "linear",
  className: "slider variable-width center",
  variableWidth: true,
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  rtl: true,
  responsive: [
   
     {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        margin:10,
      },
    },
  ],
};


export const about_slider_third = {
  dots: false,
  cssEase: "linear",
  className: "slider variable-width center",
  variableWidth: true,
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  responsive: [
   
     {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        margin:10,
      },
    },
  ],
};

export const about_slider_forth = {
  dots: false,
  cssEase: "linear",
  className: "slider variable-width center",
  variableWidth: true,
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 4000,
  rtl: true,
  pauseOnHover: true,
  responsive: [
   
     {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        margin:10,
      },
    },
  ],
};

// Signup Form Validation
export const SignUpFormValidation = (inputDetail) => {
  let isError = false;
  let errorObj = {};
  let errors = {};

  if (window.cn(inputDetail.first_name) && !window.cb(inputDetail.first_name)) {
    errors = { ...errors, first_name: "Enter First Name *" };
    isError = true;
  } else {
    errors = { ...errors, name: "" };
  }

  if (window.cn(inputDetail.last_name) && !window.cb(inputDetail.last_name)) {
    errors = { ...errors, last_name: "Enter Last Name *" };
    isError = true;
  } else {
    errors = { ...errors, name: "" };
  }

  if (window.cn(inputDetail.email) && !window.cb(inputDetail.email)) {
    errors = { ...errors, email: "Enter Email *" };
    isError = true;
  } else if (!emailPattern.test(inputDetail.email)) {
    errors = { ...errors, email: "Enter Valid Email*" };
    isError = true;
  } else {
    errors = { ...errors, email: "" };
  }

  if (window.cn(inputDetail.phone) && !window.cb(inputDetail.phone)) {
    errors = { ...errors, phone: "Enter Phone Number *" };
    isError = true;
  } else if (!phonePattern.test(inputDetail.phone)) {
    errors = { ...errors, phone: "Enter Valid Phone Number*" };
    isError = true;
  } else {
    errors = { ...errors, phone: "" };
  }

  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: "Enter Password *" };
    isError = true;
  } else {
    errors = { ...errors, password: "" };
  }

  if (window.cn(inputDetail.confirm_password) && !window.cb(inputDetail.confirm_password)) {
    errors = { ...errors, confirm_password: "Enter Password *" };
    isError = true;
  } else if(inputDetail.confirm_password !== inputDetail.password){
    errors = { ...errors, confirm_password: "Enter Same Password *" };
    isError = true;
  }
  else {
    errors = { ...errors, confirm_password: "" };
  }

  errorObj = { errors, isError };

  return errorObj;
};

//Login Form Validation
export const LoginFormValidation = (inputDetail) => {
  let isError = false;
  let errorObj = {};
  let errors = {};


  if (window.cn(inputDetail.email) && !window.cb(inputDetail.email)) {
    errors = { ...errors, email: "Enter Email *" };
    isError = true;
  } else if (!emailPattern.test(inputDetail.email)) {
    errors = { ...errors, email: "Enter Valid Email*" };
    isError = true;
  } else {
    errors = { ...errors, email: "" };
  }

  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: "Enter Password *" };
    isError = true;
  } else {
    errors = { ...errors, password: "" };
  }


  errorObj = { errors, isError };

  return errorObj;
};

//ResetPasseord Validation
export const ResetPasswordValidation = (inputDetail) => {
  let isError = false;
  let errorObj = {};
  let errors = {};


  if (window.cn(inputDetail.email) && !window.cb(inputDetail.email)) {
    errors = { ...errors, email: "Enter Email *" };
    isError = true;
  } else if (!emailPattern.test(inputDetail.email)) {
    errors = { ...errors, email: "Enter Valid Email*" };
    isError = true;
  } else {
    errors = { ...errors, email: "" };
  }

  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: "Enter Password *" };
    isError = true;
  } else {
    errors = { ...errors, password: "" };
  }


  errorObj = { errors, isError };

  return errorObj;
};

export const ChangePasswordValidation = (inputDetail) => {
  let isError = false;
  let errorObj = {};
  let errors = {};

  if (window.cn(inputDetail.old_password) && !window.cb(inputDetail.old_password)) {
    errors = { ...errors, old_password: "Enter old Password *" };
    isError = true;
  } else {
    errors = { ...errors, old_password: "" };
  }

  if (window.cn(inputDetail.new_password) && !window.cb(inputDetail.new_password)) {
    errors = { ...errors, new_password: "Enter Password *" };
    isError = true;
  } else {
    errors = { ...errors, new_password: "" };
  }

  if (window.cn(inputDetail.confirm_password) && !window.cb(inputDetail.confirm_password)) {
    errors = { ...errors, confirm_password: "Enter Confirm Password *" };
    isError = true;
  } else if(inputDetail.confirm_password !== inputDetail.new_password){
    errors = { ...errors, confirm_password: "Password are not match *" };
    isError = true;
  }
  else {
    errors = { ...errors, confirm_password: "" };
  }

  errorObj = { errors, isError };

  return errorObj;
};
export const SentForgotEmailValidation = (inputDetail) => {
  let isError = false;
  let errorObj = {};
  let errors = {};
  if (window.cn(inputDetail.email) && !window.cb(inputDetail.email)) {
    errors = { ...errors, email: "Enter Email *" };
    isError = true;
  } else if (!emailPattern.test(inputDetail.email)) {
    errors = { ...errors, email: "Enter Valid Email*" };
    isError = true;
  } else {
    errors = { ...errors, email: "" };
  }

  errorObj = { errors, isError };

  return errorObj;
};
export const AppiontmentFormValidation = (date,service,employee) => {
  let isError = false;
  let errorObj = {};
  let errors = {};


  if (window.cn(service) && !window.cb(service)) {
    errors = { ...errors, service: "Select Service *" };
    isError = true;
  } else {
    errors = { ...errors, password: "" };
  }

  if (window.cn(employee) && !window.cb(employee)) {
    errors = { ...errors, employee: "Select Employee *" };
    isError = true;
  } else {
    errors = { ...errors, password: "" };
  }


  errorObj = { errors, isError };

  return errorObj;
};

export function convertServerDate(date){
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return year + '-' + (month<=9 ? '0' + month : month) + '-' + (day <=9 ? '0' + day : day)
}