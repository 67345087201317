const ClientStory = () => {
  return (
    <>
      <div className="client-story">
        <div className="container">
          <div className="main-content">
            <div className="row one">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 right">
                <div className="right-child">
                  <h1 className="main-h">Our Client’s Stories</h1>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 left">
                <div className="second-content">
                  <div className="row align-items-start justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content first">
                        <div className="box">
                          <h4 className="p-txt">
                            "The henna designs at this studio are simply
                            stunning! The artist was incredibly skilled and
                            created a beautiful, intricate pattern for me. I was
                            so impressed by the quality and precision of their
                            work!"
                          </h4>
                          <h6 className="p-txt name">- Dhara</h6>
                        </div>

                        <div className="box">
                          <h4 className="p-txt">
                            "Amazing work and attention to detail! The artist
                            really listened to what I wanted and delivered
                            beyond my expectations. The studio has such a
                            relaxing vibe, and the henna was absolutely
                            gorgeous. Highly recommend!"
                          </h4>
                          <h6 className="p-txt name">- Diya</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="content">
                        <div className="box">
                          <h4 className="p-txt">
                            "I had such a great experience at the henna studio!
                            The staff was friendly, professional, and the henna
                            lasted for weeks. I received so many compliments,
                            and I can’t wait to come back for more designs."
                          </h4>
                          <h6 className="p-txt name">- Rajal</h6>
                        </div>

                        <div className="box">
                          <h4 className="p-txt">
                            "Hemmy’s Bridal Studio is a gem! The henna work was
                            incredible – the design was detailed and applied so
                            precisely, and the color came out even better than I
                            expected. The makeup artist worked magic on my skin,
                            creating a look that was glamorous yet natural,
                            which was exactly what I wanted. Both the henna and
                            makeup lasted all day without any issues. The staff
                            was friendly, and the entire experience felt
                            luxurious. I highly recommend them for anyone
                            looking for bridal henna and makeup that stands out.
                            "
                          </h4>
                          <h6 className="p-txt name">- Heena</h6>
                        </div>
                        {/* 
                        <div className="box">
                          <h4 className="p-txt">
                            "Cheeribook helped me preserve the memories from my
                            wedding day in the most beautiful way. The quality
                            of the book is exceptional, and I love how
                            customizable it was."
                          </h4>
                          <h6 className="p-txt name">- Matt</h6>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientStory;
