import logo from "./logo.svg";
import "./App.css";
import Layout from "./layout/layout";

function App() {
  return (
    <>
        <Layout />
    </>
  );
}

export default App;
