import Footer from "./footer";
import Header from "./header"
import Routers from "./route";

const Layout = () => {
    return(
        <>
            <Header />
            <Routers />
            <Footer />
        </>
    )
}

export default Layout;