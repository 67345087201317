import Service from "./service";
import home from "../../assets/images/hero-main.png";
import Gallary from "./gallary";
import Appointment from "./appointment";
import FeedBack from "./feedBack";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import ClientStory from "./clientStory";

const Home = () => {
  document.title = "Home | HEMMY'S BEAUTY STUDIO";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="main-home top-sec">
        <div className="container">
          <div className="main-content">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 content">
                <h1 className="main-h">
                  HEMMY'S
                  <br /> BEAUTY STUDIO
                </h1>
                <h5 className="para-txt">A PLACE FOR CORRECTIVE MAKE-UP</h5>
                <h6 className="p-txt">
                  Welcome! Learn all about my career as a certified artist right
                  here. As a professional in the industry, my main focus is
                  providing my clients with customized treatments or services
                  that suit their individual needs. I’ve accumulated many years
                  of experience. Have a look at the services I offer, and get in
                  touch to learn more.
                </h6>
                <div>
                  <NavLink to="/book-appointment">
                    <button className="primary-btn mt-4">
                      Book An Appointment
                    </button>
                  </NavLink>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 sec-content">
                <div className="img-box">
                  <img src={home} className="img-fluid" alt="home-new" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Gallary />

      <Service />

      <Appointment />
      <ClientStory />

      <FeedBack />
    </>
  );
};

export default Home;
