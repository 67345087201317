import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const saveInformation = (token, userData) => {
    setToken(token);
    setUser(userData);
  };

  const logout = () => {
    setToken(null);
    setUser({});
    navigate("/login");
    localStorage.clear();
  };

  return (
    <>
      <AuthContext.Provider value={{token , user , saveInformation , logout}}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
