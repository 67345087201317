import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/style.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/authContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

//Global Window Object
window.helicore = window.helicore || {};
window.cn = function (o) {
  return "undefined" === typeof o || null === o || "" === o.toString().trim();
};
window.cb = function (o) {
  if (o === "true") {
    return true;
  } else {
    return false;
  }
};
root.render(
  <React.StrictMode>
      <BrowserRouter>
    <AuthProvider>

        <Toaster position="top-right" />

        <App />
    </AuthProvider>

      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
