import { Fragment, useEffect, useState } from "react";
import gallary1 from "../../assets/images/HomeImage.png";
import { GET_SERVICE } from "../../config/apiUrl";
import axios from "../../config/axiosInstance";

const Appointment = () => {
  const [service, setService] = useState({});

  
  const getService = async () => {
    try {
      const response = await axios.get(GET_SERVICE);
      if (response) {
        setService(response?.data?.payload);
      }
    } catch (error) {
      setService([]);
    }
  };

  useEffect(() => {
    getService();
  }, []);

  return (
    <>
      <div className="appointment-home">
        <div className="container-fluid">
          <div className="main-content">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-5 col-12 col-sm-12 left-div px-0">
                <div className="img-box">
                  <img src={gallary1} className="img-fluid" alt="image" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 right-div">
                <h6 className="main-h text-center mb-5">
                We provide best price

                </h6>
                <div className="row">
                  {service && service.length>0 && service.map((item,index)=>(
                    <Fragment>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="pricing-list">
                      <ul>
                        
                        <li>
                        {item?.service_name}. . . . . . . . . . . . . .{" "}
                          <span>£ {item?.service_price}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                    </Fragment>
                  ))}
{/*                   
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="pricing-list">
                      <ul>
                        <li>
                          Bridal Makeup. . . . . . . . . . . . . .{" "}
                          <span>£ 50</span>
                        </li>
                        <li>
                          Hair style. . . . . . . . . . . . . .{" "}
                          <span>£ 50</span>
                        </li>
                        
                      </ul>
                    </div>
                  </div> */}
                </div>

                <div>
                  {" "}
                  <button className="primary-btn mt-3">Book An Appointment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};
export default Appointment;
