import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { LoginFormValidation } from "../../config/setting";
import { LOGIN } from "../../config/apiUrl";
import axiosInstance from "../../config/axiosInstance";
import AuthContext from "../../context/authContext";

export const Login = () => {
  document.title = "Login | HEMMY'S BEAUTY STUDIO";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setErrors] = useState({});
  const navigat = useNavigate();
  const { saveInformation } = useContext(AuthContext);

  const handleChange = (value, name) => {
    setUserData({ ...userData, [name]: value });
  };

  const handleLogin = async () => {
    const { errors, isError } = LoginFormValidation(userData);
    setErrors(errors);
    if (!isError) {
      try {
        setLoader(true);

        const response = await axiosInstance.post(LOGIN , userData)

        if (response) {

          setLoader(false);
          const { data, token } = response.data.payload;
          saveInformation(token, data);

          localStorage.setItem("jwtToken", token);
          toast.success(response.data.message);
          setUserData({});
          navigat("/");
        }
      } catch (error) {
        if (error.response) {
          setLoader(false);

          toast.error(error.response.data.message);
        }
      }
    }
  };
  return (
    <>
      <div className="login top-sec">
        <div className="container">
          <div className="main-content">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 ">
                <div className="card">
                  <h1 className="main-h text-center mb-4">Login</h1>
                  <div className="form-main-div">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Email"
                            autoComplete="off"
                            value={userData?.email || ""}
                            onChange={(e) =>
                              handleChange(e.target.value, "email")
                            }
                          />
                          <p className="red-color">{error?.email}</p>
                        </div>
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlpass"
                            placeholder="Enter Password"
                            autoComplete="off"
                            value={userData?.password || ""}
                            onChange={(e) =>
                              handleChange(e.target.value, "password")
                            }
                          />
                          <p className="red-color">{error?.password}</p>
                        </div>
                      </div>

                      <div className="col-xl-12 d-flex justify-content-center mb-4 mt-3">
                        <button
                          className="primary-btn login"
                          type="button"
                          onClick={() => handleLogin ()}
                        >
                          {loader === true ? (
                            <>
                              <i className="fa fa-circle-o-notch fa-spin"></i>
                            </>
                          ) : (
                            "Submit "
                          )}
                        </button>
                      </div>

                      <div className="col-xl-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="ac-login-txt mb-3 text-center">
                          Don't have an account ?{" "}
                          <NavLink className="inner-txt" to="/signup">
                            Signup
                          </NavLink>
                        </div>
                        <NavLink to="/forgot-password" className="inner-txt">
                          Forgot Password ?{" "}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
