const FeedBack = () => {
  return (
    <>
      <div className="feedback">
        <div className="container">
          <div className="main-content">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <h1 className="main-h text-center">SEND US YOUR FEEDBACK!</h1>
              </div>

              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                  <label>Give Your FeedBack</label>
                  <textarea
                    className="form-control w-100"
                    name="message"
                    id="message"
                    cols="30"
                    rows="7 "
                    placeholder=" Enter Message"
                  ></textarea>
                </div>
                <div className="form-group mt-3">
                <div className="send-btn">
                            <button className="contact-btn">
                              <div className="svg-wrapper-1">
                                <div className="svg-wrapper">
                                  <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                      d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                              <span>Send</span>
                            </button>
                          </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedBack;
